import React from 'react';
import Page from '../Components/Page';

const PageContainer = ({
  children,
  authenticated,
  ...props
}) => {
  return (
    <Page
      {...{
        children,
        authenticated,
        ...props
      }}
    />
  );
};

export default PageContainer