import React from 'react';
import Icon from 'react-icon-base';

const NavNominate= props => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
	<path fill="#000" d="M494 21.621c-14.947 8.43-29.566 17.581-43.67 29.227l7.318 38.547C471.923 93.66 483.583 95.26 494 95.36v-73.74zm-98.982 24.512c-15.283-.085-32.48 2.596-53.832 6.834l-.22.043-.22.033c-14.77 2.177-40.794 12.065-66.465 38.867l44.27 11.766c.972-1.493 5.936-9.004 6.88-10.555 5.124 3.123 10.248 6.244 15.372 9.365-12.475 20.475-26.742 35.556-43.934 54.522-2.123 4.718.977 8.199 4.36 10.14 5.22 2.931 14.1 3.09 16.437 2.102 23.932-15.768 40.819-35.928 55.963-56.271l5.469.964c11.501 2.031 26.47 1.058 38.707-2.853 11.098-3.548 19.272-9.357 22.662-15.688L432.54 53.65c-12.044-5.214-24.039-7.442-37.523-7.517zM227.932 98.717l-29.436 115.986 9.643.297H311.27l.9-.209 6.804-27.092c-8.86 1.9-18.296-.217-26.557-4.855-5.188-2.913-10.024-7.24-12.621-13.434-7.797-19.938 15.857-37.297 28.724-52.75l-80.59-17.943zM69.562 201l-23 46h418.875l-23-46H334.195l-3.517 14H352v18H160v-18h19.852l3.552-14H69.563zM41 265v222h430V265H41zm14 14h402v194H55V279zm18 18v118.238l34.502-74.994 73.36 31.762 66.652-45.84 37.513 57.273 50.11-4.595 31.3-39.332L439 394.627V297H73zm169.543 54.43l-90.63 62.33 27.01 41.24h95.606l19.666-24.71-51.652-78.86zm-126.045 12.326L74.521 455h82.885l-30.193-46.098 36.144-24.857-46.859-20.29zm253.065.732L297.533 455h140.54l.927-1.166v-36.602l-69.438-52.744zm-49.944 33.854l-23.426 2.148 9.805 14.969 13.621-17.117z"/>
    </g>
  </Icon>
);

export default NavNominate;
