import React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CircularProgress from '@mui/material/CircularProgress';

import { wrapFormState } from '../../Components/useFormState';

const NominateForm = ({ bindForm, bindFormData, isSubmitting }) => (
    <form noValidate autoComplete="off" {...bindForm}>
        <TextField
            label="Your Name"
            margin="normal"
            variant="outlined"
            fullWidth
            helperText="What is your name?"
            {...bindFormData('name')}
            />
        <TextField
            type="email"
            label="Your Email Address"
            margin="normal"
            variant="outlined"
            fullWidth
            helperText="What is your e-mail address?"
            {...bindFormData('email')}
            />

        <TextField
            label="Nominated Organisation"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            helperText="What good cause would you like to nominate?"
            inputProps={{
                errorhelpertext: "Please nominate a local charity or organisation"
            }}
            {...bindFormData('charity')}
            />
        
        <TextField
          label="Nominated Reason"
          margin="normal"
          multiline
          fullWidth
          rows={4}
          helperText="Why are you nominating this good cause?"
          {...bindFormData('reason')}
        />

        <TextField
          label="Nominated Purpose"
          margin="normal"
          multiline
          fullWidth
          rows={4}
          helperText="How would the good cause use the grant?"
          {...bindFormData('purpose')}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="field-nominate-amount">Amount</InputLabel>
          <OutlinedInput
            id="field-nominate-amount "
            type="number"
            startAdornment={<InputAdornment position="start">£</InputAdornment>}
            label="Amount"
            {...bindFormData('amount')}
          />
          <FormHelperText id="helper-nominate-amount">How much money are you asking for?</FormHelperText>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          style={{ width: '100%', margin: '10px 0' }}
          type="submit"
          disabled={isSubmitting}
        >
          Submit
          {isSubmitting && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
        </Button>
    </form>
);

export default wrapFormState(NominateForm);
