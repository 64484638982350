import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

import { useCreateNomineeMutation } from '../../Services/Nominee' 

import Card from '../../Components/Card'
import Blur from '../../Components/Blur'

import Form from './nominateForm'

const Nominate = ({...props}) => {
    const [createNominee] = useCreateNomineeMutation()
    const [nominated, setNominated] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = ({amount, ...formData}) => e => {
        setIsSaving(true)
        createNominee({amount: parseFloat(amount), ...formData})
        .then(result => {
            if (result.error) {
                throw new Error(result.error)
            }
            setNominated(true)
        })
        .catch(error => setError(error))
        .finally(() => {
            setIsSaving(false)
        })
    };
      
    return (
        <Blur>
            <Card sx={{ maxWidth: 900 }} title="2024 Cash Giveaway" {...props}>
                <Typography variant="body1" color='text.secondary'>
                    If you know of a charity or other good cause in the Romsey and Chandler's Ford area that could make use of a cash grant, please nominate them for our Christmas Cash Giveaway! Just answer a few questions and we'll do the rest. 
                </Typography>
                <Typography variant="body1" color='text.secondary'>
                    Applications close at 6 pm on Sunday 14th January 2024.
                </Typography>
                { error && 
                    <Typography variant="body1" color='text.secondary'>
                        Something went wrong while saving your nomination, please try again later.
                    </Typography> 
                }
                {!nominated ?
                    <Form handleSubmit={handleSubmit} isSubmitting={isSaving}/>
                : <Typography variant="body1" color='text.secondary'>
                    Thank you for taking the time to nominate a good cause in the local area.
                </Typography>
                }
            </Card>
        </Blur>
    )
}

export default Nominate