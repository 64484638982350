import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/system';

import ButtonBase from '@mui/material/ButtonBase';

const NavigationItem = ({ title, icon, ...props }) => {
  let Component = icon ?? 'div';

  return (
    <StyledNavLink component={props.href && NavLink} to={props.href} {...props}>
      <Component
        size={40}
        style={{ display: 'block', padding: '5px', margin: 'auto' }}
      />
      {title}
    </StyledNavLink>
  );
};

NavigationItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: function(props, propName, componentName) {
    if (
      !props.onClick &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(
        '(string)href is required, when not using an onClick handler.'
      );
    }
  },
  onClick: PropTypes.func,
  icon: PropTypes.func,
  hide: PropTypes.bool,
  compact: PropTypes.string
};

export default NavigationItem;

const StyledNavLink = styled(ButtonBase)(
  {
    display: 'block !important',
    padding: '10px !important',
    textAlign: 'center',
    textDecoration: 'none',
    fontWeight: 'lighter',
    fontSize: 14,
    boxSizing: 'border-box',
    flexGrow: 0,
    flexShrink: 0
  },
  ({ theme }) => ({
    width: theme.layout.size.menu,
    color: theme.palette.primary.contrastText,
    '&.active': {
      backgroundColor: theme.palette.primary.light
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ({ compact, theme }) =>
    compact && {
      [theme.layout.size.full]: {
        display: 'none !important'
      }
    },
  ({ hide }) =>
    hide && {
      display: 'none !important'
    }
);
