import React from 'react';
import Icon from 'react-icon-base';

const NavTracker = props => (
  <Icon viewBox="0 0 495.547 495.547" {...props}>
    <g>
		<path d="M469.211,4.535H26.335C11.79,4.535,0,16.325,0,30.868V464.68c0,14.543,11.79,26.332,26.335,26.332h442.878
			c14.545,0,26.334-11.789,26.334-26.332V30.868C495.547,16.325,483.756,4.535,469.211,4.535z M247.773,442.375
			c-107.475,0-194.601-87.127-194.601-194.602S140.298,53.172,247.773,53.172s194.6,87.126,194.6,194.601
			S355.248,442.375,247.773,442.375z M432.553,75.966c-7.975,0-14.439-6.465-14.439-14.438c0-7.976,6.465-14.44,14.439-14.44
			s14.439,6.465,14.439,14.44C446.992,69.502,440.527,75.966,432.553,75.966z"/>
		<path d="M390.742,189.354l12.768-4.507c-16.658-59.661-84.822-106.938-155.737-106.938c-93.813,0-169.864,76.052-169.864,169.864
			c0,93.814,76.051,169.866,169.864,169.866s169.864-76.052,169.864-169.866c0-1.607-0.025-3.209-0.07-4.807h-167.66l15.631-34.008
			c3.969,2.991,6.98,7.188,8.48,12.044l12.584-3.881c-2.619-8.476-8.205-15.659-15.537-20.338l4.858-10.699
			c17.252,8.356,29.282,25.859,29.753,46.175h15.66c-0.477-26.643-16.271-49.613-38.936-60.444l6.824-14.696
			c20.104,10.433,36.66,25.881,43.451,47.854l14.859-4.582c-8.105-26.235-27.215-46.263-51.329-58.537l5.218-11.93
			c39.809,18.159,67.709,58.012,68.529,104.357l15.654-0.226c-0.906-52.577-33.104-96.692-78.258-117.29l4.391-9.705
			C347.271,122.987,379.523,152.379,390.742,189.354z M143.891,333.412c-3.438,0-6.222-2.785-6.222-6.221
			c0-3.438,2.784-6.224,6.222-6.224c3.437,0,6.222,2.785,6.222,6.224C150.113,330.627,147.328,333.412,143.891,333.412z
			 M151.022,218.003c-5.46,0-9.886-4.427-9.886-9.887c0-5.459,4.426-9.885,9.886-9.885c5.461,0,9.887,4.426,9.887,9.885
			C160.909,213.577,156.483,218.003,151.022,218.003z M187.671,255.471c-4.251,0-7.696-3.445-7.696-7.698
			c0-4.25,3.445-7.695,7.696-7.695c4.25,0,7.695,3.445,7.695,7.695C195.367,252.025,191.921,255.471,187.671,255.471z
			 M289.552,314.839c7.285,0,13.192,5.908,13.192,13.192c0,7.285-5.907,13.193-13.192,13.193c-7.286,0-13.192-5.908-13.192-13.193
			C276.359,320.747,282.266,314.839,289.552,314.839z"/>
    </g>
  </Icon>
);

export default NavTracker;
