import React from 'react';
import { useGetRoutesQuery } from '../../Services/Routes'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Card from '../../Components/Card'
import RouteCard from '../../Components/RouteCard'
import Blur from '../../Components/Blur'

const Routes = ({fetchRoutes, ...props}) => {
    const { data, error, isLoading } = useGetRoutesQuery(new Date(2023, 0))

    const handleOnClick = (routeID) => () => {
        alert(`Coming soon`)
    }

    return (
        <Blur>
            <Card sx={{ maxWidth: 900 }} title="Santa Claus is Comin' To Town">
            {error ? (
                <Typography variant="body1" color='text.secondary'>
                    Error loading routes, please try again later.
                </Typography>
            ) : isLoading ? (
                <Typography variant="body1" color='text.secondary'>
                    Loading...
                </Typography>
            ) : data ? (
                <Grid container spacing={2}>
                    {data.map( (route, i) => (
                    <Grid item xs={12} md={4} key={route.node.id}>
                        <RouteCard date={route.node.startDate} area={route.node.area} description={route.node.description} onClick={handleOnClick(route.node.id)} disabled={new Date(route.node.endDate) < new Date()} />
                    </Grid>
                    ))}
                </Grid>
            ) : null}
            </Card>
        </Blur>
    )
}

export default Routes;