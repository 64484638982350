import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef
} from 'react';

export const wrapFormState = WrappedComponent =>
  forwardRef(function Component(
    {
      onChange: originalOnChange = e => {},
      defaultFormState,
      handleSubmit,
      ...props
    },
    ref
  ) {
    const [formData, setFormData] = useState({ ...defaultFormState });
    const [errorData, setErrorData] = useState({});
    const formRef = useRef();

    useEffect(() => {
      //If the defaultFormState changes, update the local state.
      defaultFormState && setFormData(defaultFormState);
    }, [defaultFormState]);

    useImperativeHandle(ref, () => ({
      submit: () => {
        formRef.current.dispatchEvent(new Event('submit'));
      }
    }));

    const onChange = e => {
      e.target.setCustomValidity("")
      if( !e.target.checkValidity() ) {
        let errorMessage = e.target.getAttribute('errorhelpertext')
        errorMessage && e.target.setCustomValidity(errorMessage);
      }

      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });

      setErrorData({
        ...errorData,
        [e.target.name]: e.target.validationMessage
      })

      originalOnChange(e);
    };

    const bindFormData = name => ({
      name: name,
      value: formData[name] || '',
      error: errorData[name],
      [errorData[name] && "helperText"]: errorData[name],
      onChange
    });

    const bindForm = {
      name: 'myForm',
      onSubmit: e => {
        e.preventDefault();
        if (e.target.checkValidity()) {
          handleSubmit(formData)(e);
        } else {
          [...e.target.elements].forEach(element => {
            element.dispatchEvent(new Event('input', { bubbles: true }));
          });
        }

        return false;
      },
      ref: formRef
    };

    return <WrappedComponent {...{ ...props, bindForm, bindFormData }} />;
  });
