import React from 'react';
import { styled } from '@mui/system';

const Navigation = props => <StyledNav>{props.children}</StyledNav>;

export default Navigation;

const StyledNav = styled('nav')(
  {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  ({ theme }) => ({
    [theme.layout.size.compact]: {
      flexFlow: 'row nowrap',
    }
  })
);
