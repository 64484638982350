import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

export const donationAPI = createApi({
  reducerPath: 'donation',
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.REACT_APP_GRAPH_URL,
    requestHeaders: {
        "X-Parse-Application-Id": process.env.REACT_APP_ID
    }
  }),
  endpoints: (builder) => ({
    createDonation: builder.mutation({
      query: (data) => {
        const {amount, success} = data
        return {
        document: gql`
            mutation createADonation($amount: Float!, $success: Boolean) {
                createDonation(input: {
                  fields: {
                    device: "web"
                    amount: $amount
                    success: $success
                    ACL: {
                      public: {
                        read: true
                        write: false
                      }
                    }
                  }
                }) {
                  clientMutationId
                  donation {
                    id
                    device
                    amount
                    success
                  }
                }
            }
        `,
        variables: {
          amount,
          success
        }
      }},
    }),
  }),
})

export const { useCreateDonationMutation } = donationAPI
