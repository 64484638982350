import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'

import { routesAPI } from '../Services/Routes'
import { locationAPI } from '../Services/Location'
import { donationAPI } from '../Services/Donation'
import { nomineeAPI } from '../Services/Nominee'

const store = configureStore({
  reducer: {
    [routesAPI.reducerPath]: routesAPI.reducer,
    [locationAPI.reducerPath]: locationAPI.reducer,
    [donationAPI.reducerPath]: donationAPI.reducer,
    [nomineeAPI.reducerPath]: nomineeAPI.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    routesAPI.middleware, 
    locationAPI.middleware, 
    donationAPI.middleware,
    nomineeAPI.middleware
  )
});

setupListeners(store.dispatch)

export default store;
