import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { grey, orange } from '@mui/material/colors';

import Page from './Containers/Page';
import Donation from './Containers/Donation';
import {default as MyRoutes} from './Containers/Routes';
import Fundraising from './Containers/Fundraising';
import Nominate from './Containers/Nominate';
import Tracker from './Containers/Tracker';
import PageNotFound from './Components/PageNotFound';

const MENU_WIDTH = 100;

function App() {
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const muiTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: orange,
          background: {
            light: prefersDarkMode ? grey[600] : grey[100],
            main: prefersDarkMode ? grey[700] : grey[50],
            dark: prefersDarkMode ? grey[800] : grey[200],
            darkest: prefersDarkMode ? grey[900] : grey[300],
            contrastText: prefersDarkMode ? grey[200] : '#444',
          },
        },
        layout: {
          size: {
            menu: MENU_WIDTH,
            compact: theme.breakpoints.down('sm'),
            full: theme.breakpoints.up('sm'),
          }
        },
      }),
    [prefersDarkMode, theme],
  );

  const [map, setMap] = useState();

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
        <BrowserRouter>
          <Page map={map} setMap={setMap}>
            <Routes>
                <Route path="/" element={<Donation />} />
                <Route exact path="/donate" element={<Donation />} />
                {/* <Route exact path="/routes" element={<MyRoutes />} /> */}
                <Route exact path="/fundraising" element={<Fundraising />} />
                {/* <Route exact path="/nominate" element={<Nominate />} /> */}
                {/* <Route exact path="/tracker" element={<Tracker map={map}/>} /> */}
                <Route path="*" element={<PageNotFound />} status={404} /> 
              </Routes>
            </Page>
          </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
