import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';

import Card from '../../Components/Card'
import Blur from '../../Components/Blur'

const Fundraising = ({...props}) => {

    const orgs = ["Romsey Family Support Group",
    "Woodley Preschool Charity",
    "Greenbuttercup CIC",
    "Baby Necessities Southampton",
    "Braishfield Social Contact Group",
    "Hampshire Search and Rescue",
    "Nomads Drama Group",
    "Man Gang",
    "Romsey Chamber Music Fesitval",
    "Romsey Young Carers",
    "Jane Scarth House",
    "Romsey Opportunity Group",
    "Youth Options",
    "Youth in Romsey",
    "Simon Says",
    "Romsey Food Bank"]

    return (
        <Blur>
            <Card sx={{ maxWidth: 900 }} title="Where your money goes" subtitle={<Typography variant="subtitle1" color='text.secondary' component="div">
                    Last year, with the help of your donations Romsey, Chandler’s Ford & District Round Table raised a total of &nbsp;
                    <Typography variant="h3" component="span" color='text.primary'>
                        £9975
                    </Typography>
                </Typography>}>
                
                <Grid container spacing={2}>
                {orgs.map((org, i) => (
                    <Grid item xs={12} md={6} key={org}>
                        <Typography sx={{ mb: 1.5 }} component={StyledDiv} variant="h5" {...{color: (Math.floor(i/2) % 2 === 0) ? (i % 2 === 0 ? 'text.primary' : 'text.secondary') : (i % 2 === 0 ? 'text.secondary' : 'text.primary')}}>
                            {org}
                        </Typography>
                    </Grid>
                ))}
                </Grid>
                <Typography variant="body1" color='text.secondary'>
                    <p>In 2023 we gave away £9,600 to help the above charities and organisations. </p>
                    <p>Our running costs were just £434. The deficit was taken from the 2022 reserve!</p>
                </Typography>
            </Card>
        </Blur>
    )
}

export default Fundraising

const StyledDiv = styled('div')(
    {
      fontFamily: "Veneer Two !important",
      display: 'block',
      marginBottom: "0 !important"
    }
  );
  