import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

export const routesAPI = createApi({
  reducerPath: 'routes',
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.REACT_APP_GRAPH_URL,
    requestHeaders: {
        "X-Parse-Application-Id": process.env.REACT_APP_ID
    }
  }),
  endpoints: (builder) => ({
    getRoutes: builder.query({
      query: (currentYear) => ({
        document: gql`
            query getRoutes($currentYear:Date!) {
                routes(where: {
                  startDate:{
                    greaterThanOrEqualTo: $currentYear
                  }
                }, order: startDate_ASC) {
                    count
                    edges {
                        node {
                        id,
                        objectId,
                        area
                        description
                        meetingPoint {
                            latitude
                            longitude
                        }
                        startDate
                        endDate
                        polyline
                        }
                    }
                }
            }
        `,
        variables: {
          currentYear: currentYear
        }
      }),
      transformResponse: (response) => response.routes.edges,
    }),
    getRoute: builder.query({
      query: (id) => ({
        document: gql`
            query getRoute($id: ID!) {
                route(id: $id) {
                    id,
                    objectId,
                    area
                    description
                    meetingPoint {
                        latitude
                        longitude
                    }
                    startDate
                    endDate
                    polyline
                }
            }
        `,
        variables: {
          id
        },
      }),
      transformResponse: (response) => response.route,
    }),
    getNextRoute: builder.query({
      query: (currentDate) => ({
        document: gql`
          query getNextRoute($currentDate:Date!) {
            routes(where: {
              endDate:{
                greaterThanOrEqualTo: $currentDate
              }
            }, first: 1) {
              edges {
                node {
                  id,
                  objectId,
                  area
                  description
                  meetingPoint {
                    latitude
                    longitude
                  }
                  startDate
                  endDate
                  polyline
                }
              }
            }
          }
        `,
        variables: {
          currentDate: currentDate
        }
      }),
      transformResponse: (response) => response.routes.edges[0].node,
    }),
  }),
})

export const { useGetRoutesQuery, useGetRouteQuery, useGetNextRouteQuery } = routesAPI
