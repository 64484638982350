import React from 'react';

import Card from './Card'

import Typography from '@mui/material/Typography';

const DonateButton = ({amount, onClick, ...props}) => {

    return (
        <Card sx={{ minWidth: 200 }} actions={[{title: "Donate", onClick}]}>
            <Typography variant="h5" component="div">£{amount}.00</Typography>
        </Card>
    )
}

export default DonateButton;
