import React from 'react';
import { styled } from '@mui/system';

const Blur = ({children, ...props}) => (
  <StyledSection {...props}>{children}</StyledSection>
);

export default Blur;

const StyledSection = styled('section')(
  {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backdropFilter: 'blur(10px)',
  },
  ({ flex }) => flex && {
    flexGrow: 1,
    height: 'auto'
  },
);
