import React from 'react';
import { styled } from '@mui/system';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const MyCard = ({title, subtitle, headerActions, actions, children, ...props}) => (
    <Card {...props}>
        {(title || subtitle || headerActions) && (
        <StyledCardHeader title={title} titleTypographyProps={{variant: "h3"}} subheader={subtitle} action={headerActions} />
        )}
        {children && (
            <CardContent>
                <Typography component="div">{children}</Typography>
            </CardContent>
        )}
        {actions && actions.length > 0 && (
        <CardActions>
          {actions.map(
            ({
              title,
              component = Button,
              size = 'small',
              color = 'primary',
              ...props
            }) => (
              <Button
                key={title}
                component={component}
                size={size}
                color={color}
                {...props}
              >
                {title}
              </Button>
            )
          )}
        </CardActions>
      )}
    </Card>
);

export default MyCard;

const StyledCardHeader = styled(CardHeader)({
    fontFamily: 'Veneer Two'
});