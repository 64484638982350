import React, { useState, useRef } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useCreateDonationMutation } from '../../Services/Donation'

import Card from '../../Components/Card'
import DonateButton from '../../Components/DonateButton'
import Blur from '../../Components/Blur'

const Donation = ({...props}) => {
    const myRef = useRef(null)
    const [createDonation] = useCreateDonationMutation()
    const [donated, setDonated] = useState(false)
    const [error, setError] = useState(false)

    const handleOnClick = (amount) => () => {
        const formData = new URLSearchParams();
        formData.append('amount', amount);

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

        fetch(`${process.env.REACT_APP_SERVER_URL}/functions/sumupCheckout`, {
            method: "POST",
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_ID,
            },
            body: formData
        })
        .then(response => response.json())
        .then(result => {
            var sumupCard = window.SumUpCard.mount({
                checkoutId: result.result.id,
                donateSubmitButton: true,
                onResponse: function(type, body) {
                    if (type === "success" || type === "error" ) {
                        if (type === "success") {
                            if (body.status === "PAID") {
                                setDonated(true)
                                setError(null)
                                sumupCard.unmount()
                            } else {
                                setError("Donation failed")
                            }
                        } else if (type === "error") {
                            if (body.message) { 
                                setError(body.message)
                            } else {
                                setError("Donation failed")
                            }
                        }

                        createDonation({amount, success: donated === true})
                        .then((result) => {
                            // handle the success!
                            console.log('Update Result', result);
                        })
                        .catch((error) => console.error('Update Error', error))
                    }
                }
            });
        })
        .catch(error => {
          console.error('Error:', error);
          setError(error)
        })
    }

    return (
        <Blur>
            <Card sx={{ maxWidth: 900 }}title="Donate to Romsey, Chandler's Ford and District" subtitle="Raising money for local charities and organisations.">
                {!donated ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DonateButton amount="2" onClick={handleOnClick(2)}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DonateButton amount="5" onClick={handleOnClick(5)}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DonateButton amount="10" onClick={handleOnClick(10)}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DonateButton amount="20" onClick={handleOnClick(20)}/>
                    </Grid>
                </Grid>
                ) : (
                <Typography variant="body1" color='text.secondary'>
                    Thank you for donating. The money will be distributed to local good causes in January.
                </Typography> 
                )}
                { error && 
                    <Typography variant="body1" color='text.secondary'>
                        Something went wrong while processing your donation, please try again later.
                    </Typography> 
                }
                <div id="sumup-card" ref={myRef}></div>
            </Card>
        </Blur>
    )
}

export default Donation