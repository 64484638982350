import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

export const nomineeAPI = createApi({
  reducerPath: 'nominee',
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.REACT_APP_GRAPH_URL,
    requestHeaders: {
        "X-Parse-Application-Id": process.env.REACT_APP_ID
    }
  }),
  endpoints: (builder) => ({
    createNominee: builder.mutation({
      query: (data) => {
        const {name, email, charity, reason, purpose, amount} = data
        return {
        document: gql`
            mutation createANominee($name: String, $email: String, $charity: String!, $reason: String, $purpose: String, $amount: Float!) {
                createNominee(input: {
                  fields: {
                    name: $name
                    email: $email
                    charity: $charity
                    reason: $reason
                    purpose: $purpose
                    amount: $amount
                    ACL: {
                      public: {
                        read: true
                        write: false
                      }
                    }
                  }
                }) {
                  clientMutationId
                  nominee {
                    name
                    email
                    charity
                    reason
                    purpose
                    amount
                  }
                }
            }
        `,
        variables: {
          name,
          email,
          charity,
          reason,
          purpose,
          amount
        }
      }},
    }),
  }),
})

export const { useCreateNomineeMutation } = nomineeAPI
