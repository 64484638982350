import React from 'react';
import Icon from 'react-icon-base';

const NavRoutes = props => (
  <Icon viewBox="0 0 58 58" {...props}>
    <g>
      <path d="M51,1.5c-3.519,0-6.432,2.613-6.92,6H40v4c0,0.552-0.447,1-1,1s-1-0.448-1-1v-4h-2v6c0,0.552-0.447,1-1,1s-1-0.448-1-1v-6
	H13.92c-0.488-3.387-3.401-6-6.92-6c-3.859,0-7,3.14-7,7v41c0,3.86,3.141,7,7,7h10v-6c0-0.552,0.447-1,1-1s1,0.448,1,1v6h2v-3
	c0-0.552,0.447-1,1-1s1,0.448,1,1v3h28c3.859,0,7-3.14,7-7v-41C58,4.64,54.859,1.5,51,1.5z M12,44.605
	C10.729,43.307,8.957,42.5,7,42.5s-3.729,0.807-5,2.105V8.5c0-2.757,2.243-5,5-5s5,2.243,5,5V44.605z M18.707,25.207l-1.414-1.414
	l2.293-2.293l-2.293-2.293l1.414-1.414L21,20.086l2.293-2.293l1.414,1.414L22.414,21.5l2.293,2.293l-1.414,1.414L21,22.914
	L18.707,25.207z M21.378,29.961c-0.426,0-0.819-0.273-0.954-0.7C20.143,28.367,20,27.438,20,26.5c0-0.552,0.447-1,1-1s1,0.448,1,1
	c0,0.734,0.111,1.461,0.332,2.161c0.166,0.527-0.127,1.088-0.654,1.254C21.578,29.946,21.477,29.961,21.378,29.961z M26.314,34.508
	c-0.188,0.313-0.519,0.486-0.858,0.486c-0.175,0-0.353-0.046-0.514-0.143c-1.066-0.64-1.995-1.436-2.759-2.366
	c-0.351-0.427-0.289-1.057,0.138-1.408c0.43-0.351,1.059-0.288,1.407,0.138c0.619,0.753,1.373,1.399,2.243,1.921
	C26.445,33.42,26.599,34.035,26.314,34.508z M34.415,36.178c-0.135,0.427-0.528,0.7-0.954,0.7c-0.1,0-0.2-0.015-0.3-0.046
	c-0.7-0.22-1.427-0.332-2.161-0.332c-0.915,0-1.826-0.103-2.709-0.305c-0.538-0.123-0.874-0.66-0.751-1.198
	c0.124-0.539,0.658-0.879,1.198-0.751C29.474,34.415,30.234,34.5,31,34.5c0.938,0,1.867,0.143,2.761,0.424
	C34.288,35.09,34.581,35.651,34.415,36.178z M37.637,40.471c-0.521-0.869-1.168-1.624-1.921-2.243
	c-0.427-0.351-0.488-0.981-0.138-1.408c0.351-0.425,0.98-0.488,1.407-0.137c0.932,0.766,1.728,1.694,2.366,2.759
	c0.284,0.474,0.131,1.088-0.343,1.372c-0.161,0.097-0.339,0.143-0.514,0.143C38.155,40.957,37.824,40.784,37.637,40.471z M40,46.5
	c-0.553,0-1-0.448-1-1c0-0.768-0.085-1.528-0.254-2.261c-0.124-0.539,0.212-1.075,0.751-1.199c0.538-0.122,1.074,0.212,1.198,0.75
	C40.897,43.67,41,44.582,41,45.5C41,46.052,40.553,46.5,40,46.5z M56,44.605c-1.271-1.298-3.043-2.105-5-2.105s-3.729,0.807-5,2.105
	V8.5c0-2.757,2.243-5,5-5s5,2.243,5,5V44.605z" />
    </g>
  </Icon>
);

export default NavRoutes;
