import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Navigation from '../Components/Navigation';
import NavigationItem from '../Components/NavigationItem';

import IconDonate from './Icons/NavDonate';
import IconRoutes from './Icons/NavRoutes';
import IconFundraising from './Icons/NavFundraising';
import IconNominate from './Icons/NavNominate';
import IconTracker from './Icons/NavTracker';
import IconFacebook from './Icons/NavFacebook';

const Header = ({ authenticated, ...props }) => (
  <StyledHeader>
    <Navigation>
    <NavigationItem
        title="Donate"
        href="/donate"
        icon={IconDonate}
      />
      {/* <NavigationItem
        title="Routes"
        href="/routes"
        icon={IconRoutes}
      /> */}
      <NavigationItem
        title="Fundraising"
        href="/fundraising"
        icon={IconFundraising}
      />
      {/* <NavigationItem
        title="Nominate"
        href="/nominate"
        icon={IconNominate}
      />
      <NavigationItem
        title="Tracker"
        href="/tracker"
        icon={IconTracker}
      /> */}
      <NavigationItem
        title="Facebook"
        as="a"
        href="https://facebook.com/RomseyRT"
        target="_blank"
        icon={IconFacebook}
      />
    </Navigation>
  </StyledHeader>
);

Header.propTypes = {
  authenticated: PropTypes.bool
};

export default Header;

const StyledHeader = styled('header')(
  {
    position: 'fixed',
    height: '100%',
    overflowY: 'auto',
    zIndex: 2
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: theme.layout.size.menu,
    [theme.layout.size.compact]: {
      width: '100%',
      height: theme.layout.size.menu,
      overflowY: 'initial',
      overflowX: 'auto',
      bottom: '0',
      paddingBottom: 'env(safe-area-inset-bottom)'
    }
  })
);