import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

export const locationAPI = createApi({
  reducerPath: 'location',
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.REACT_APP_GRAPH_URL,
    requestHeaders: {
        "X-Parse-Application-Id": process.env.REACT_APP_ID
    }
  }),
  endpoints: (builder) => ({
    getSantasLocation: builder.query({
      query: (params) => { 
        const {startDate, endDate} = params
        return {
        document: gql`
          query getSantasLocation($startDate:Date!, $endDate:Date!) {
            locations(where: {
              device: {
                equalTo: "Sleigh 02"
              },
              AND:{
                timestamp:{
                  greaterThanOrEqualTo: $startDate
                  lessThanOrEqualTo: $endDate
                }
              }
            }, first: 1, order: timestamp_DESC) {
              edges {
                node {
                  id,
                  objectId
                  coordinate {
                    latitude
                    longitude
                  }
                  accuracy
                  speed
                  timestamp
                }
              }
            }
          }
        `,
        variables: {
          startDate: startDate,
          endDate: endDate
        }
      }},
      transformResponse: (response) => response.locations.edges[0].node,
    }),
  }),
})

export const { useGetSantasLocationQuery } = locationAPI
