import React from 'react';
import moment from 'moment';

import Typography from '@mui/material/Typography';

import Card from './Card'

const RouteCard = ({date, area, description, onClick, disabled, ...props}) => {   
    return (
        <Card sx={{ minWidth: 200, opacity: disabled ? 0.2 : 1 }} actions={[{title: null, onClick}]}>
            <Typography variant="subtitle1" component="div">{moment(date).format('LL - HH:mm')}</Typography>
            <Typography variant="h4" component="div">{area}</Typography>
            <Typography variant="body1" component="div">{description}</Typography>
        </Card>
    )
}

export default RouteCard;
