import React, {useRef, useEffect} from 'react';
import { styled } from '@mui/system';
import { Wrapper } from "@googlemaps/react-wrapper";

const MapStatus = (status) => {
    return <div>{status}</div>;
}

const MapWrapper = ({center = { lat: 50.9927129, lng: -1.4916072 }, zoom = 15, ...props}) => {
    return (
        <Wrapper apiKey={"AIzaSyBr5kTmweCev33qcVCInSQm3rMdkHZYQ2I"} render={MapStatus}>
            <StyledMap center={center} zoom={zoom} {...props}/>
        </Wrapper>
    );
}

const Map = ({center, zoom, map, setMap, ...props}) => {
    const ref = useRef();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                center, 
                zoom
            }));
        }
    }, [ref, map, setMap, center, zoom]);

    return <div ref={ref} id="map" {...props}/>;
}

export default MapWrapper;

const StyledMap = styled(Map)(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
);
