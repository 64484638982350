import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Header from './Header';
import Map from './Map';

const Page = ({ children, map, setMap, authenticated, ...props }) => (
  <StyledPage>
    <Header authenticated={authenticated} />
    <StyledMain>
      <StyledSpacerFull />
      <StyledDetail>
        <Map map={map} setMap={setMap}/>
        {children}
      </StyledDetail>
      <StyledSpacerCompact />
    </StyledMain>
  </StyledPage>
);

Page.propTypes = {
  children: PropTypes.element.isRequired,
  authenticated: PropTypes.bool
};

export default Page;

const StyledPage = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  backgroundColor: '#fff',
  minHeight: '100vh',
  display: 'flex'
}));

const StyledMain = styled('main')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  [theme.layout.size.compact]: {
    flexDirection: 'column'
  },
  backgroundColor: theme.palette.background.main,
  color: theme.palette.background.contrastText
}));

const StyledSpacerCompact = styled('div')(({ theme }) => ({
  flexShrink: 0,
  [theme.layout.size.compact]: {
    transition: 'none',
    height: `calc(${theme.layout.size.menu}px + env(safe-area-inset-bottom))`
  }
}));

const StyledSpacerFull = styled('div')(({ theme }) => ({
  flexShrink: 0,
  width: theme.layout.size.menu,
  transition: 'width 0.4s ease-in-out',
  [theme.layout.size.full]: {
    transition: 'width 0.5s ease-in-out',
    width: theme.layout.size.menu + theme.layout.size.master
  }
}));

const StyledDetail = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100vh'
}));
