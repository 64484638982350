import React, { useEffect } from 'react';
import { useGetNextRouteQuery } from '../../Services/Routes'
import { useGetSantasLocationQuery } from '../../Services/Location'

import Typography from '@mui/material/Typography';

import Card from '../../Components/Card'
import RouteCard from '../../Components/RouteCard'
import Blur from '../../Components/Blur'
  
const Tracker = ({map, ...props}) => {
    const { data, error, isLoading } = useGetNextRouteQuery(new Date())
    const { data: location } = useGetSantasLocationQuery(data, { skip: !data, pollingInterval: 3000, })

    const handleOnClick = (routeID) => () => {
        alert(`Coming soon`)
    }

    useEffect(() => {
        if (location) {
            if (map) {
                let latlng = { lat: location.coordinate.latitude, lng: location.coordinate.longitude };
                new window.google.maps.Marker({
                    position: latlng,
                    map: map,
                    title: "Santa Claus",
                });

                map.setCenter(latlng)
            }
        }
    }, [map, location])

    return (<div>
        {error ? (
            <Blur>
                <Card sx={{ maxWidth: 900 }} title="Santa Claus is Comin' To Town">
                    <Typography variant="body1" color='text.secondary'>
                        Error loading tracker, please try again later.
                    </Typography>
                </Card>
            </Blur>
        ) : isLoading ? (
            <Blur>
                <Card sx={{ maxWidth: 900 }} title="Santa Claus is Comin' To Town">
                    <Typography variant="body1" color='text.secondary'>
                        Loading...
                    </Typography>
                </Card>
            </Blur>
        ) : data ? (
            <div>
                {new Date(data.startDate) > new Date() &&
                    <Blur>
                        <Card sx={{ maxWidth: 900 }} title="Santa Claus is Comin' To Town">
                            <Typography variant="body1" color='text.secondary'>
                                Santa is currently at the north pole, but will be visiting again shortly.
                            </Typography>
                            <Typography variant="body1" color='text.secondary'>
                                Visit us on the next route below or come back later to see him tracked live.
                            </Typography>
                            <RouteCard date={data.startDate} area={data.area} description={data.description} onClick={handleOnClick(data.id)} />
                        </Card>
                    </Blur>
                }
            </div>
        ) : null}
    </div>)
}

export default Tracker