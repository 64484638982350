import React from 'react';
import Icon from 'react-icon-base';

const NavDonate = props => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g>
      <path d="M47.74,22H2.26L5.61,11.12A2.98216,2.98216,0,0,1,8.48,9H15.05c-.03.33-.05.66-.05,1a9.93438,9.93438,0,0,0,2.01,6H16a1,1,0,0,0,0,2H34a1,1,0,0,0,0-2H32.99A9.93438,9.93438,0,0,0,35,10c0-.34-.02-.67-.05-1h6.57a2.98209,2.98209,0,0,1,2.87,2.12ZM28.61475,29a4.08076,4.08076,0,0,0-2.90723,1.208,1.03055,1.03055,0,0,1-1.415,0A4.08076,4.08076,0,0,0,21.38525,29a4.12359,4.12359,0,0,0-4.1123,4.126c0,4.38574,5.93652,8.542,7.727,9.69629,1.79053-1.1543,7.727-5.31055,7.727-9.69629A4.12359,4.12359,0,0,0,28.61475,29ZM38,29a.99974.99974,0,0,0,1,1h9v5H39a1,1,0,0,0,0,2h9v5H39a1,1,0,0,0,0,2h9v1a3.00328,3.00328,0,0,1-3,3H5a3.00328,3.00328,0,0,1-3-3V44h9a1,1,0,0,0,0-2H2V37h9a1,1,0,0,0,0-2H2V30h9a1,1,0,0,0,0-2H2V24H48v4H39A.99974.99974,0,0,0,38,29Zm-3.273,4.126A6.12619,6.12619,0,0,0,28.61475,27,6.05647,6.05647,0,0,0,25,28.18457,6.05647,6.05647,0,0,0,21.38525,27a6.12619,6.12619,0,0,0-6.1123,6.126c0,6.28515,8.84668,11.51855,9.22314,11.73828a1.01922,1.01922,0,0,0,1.00782,0C25.88049,44.64446,34.72705,39.41109,34.72705,33.126ZM17,10a8,8,0,1,1,13.27374,6H19.72626A7.97566,7.97566,0,0,1,17,10Zm7-3a.99974.99974,0,0,0,1,1,2.00229,2.00229,0,0,1,2,2,1,1,0,0,0,2,0,4.00427,4.00427,0,0,0-4-4A.99974.99974,0,0,0,24,7Zm-3,3a4.00427,4.00427,0,0,0,4,4,1,1,0,0,0,0-2,2.00229,2.00229,0,0,1-2-2,1,1,0,0,0-2,0Z" />
    </g>
  </Icon>
);

export default NavDonate;
